import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { MdChevronRight } from "react-icons/md";
import Container from "../../../components/Container";
import Layout from "../../../components/Layout";
import Seo from "../../../components/Seo";
import Separator from "../../../components/Separator";
import halfAndFullBoardMenu from "../../../constants/halfAndFullBoardMenu";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["common", "page-ristorante"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default function Menu() {
    const {
        i18n: { language },
        t,
    } = useTranslation();

    return (
        <Layout hideQuickLinks={true}>
            <Seo title={t("page-ristorante:theHalfAndFullBoardMenu")} />
            <Container>
                <h1>{t("page-ristorante:theHalfAndFullBoardMenu")}</h1>
                <small className="pb-4">{t("page-ristorante:menuDisclamer")}</small>
                <small>{t("page-ristorante:halfAndFullBoardMenuDisclamer")}</small>
                {halfAndFullBoardMenu.sections.map((section, sectionIdx) => (
                    <div key={sectionIdx}>
                        <Separator />
                        <span className="text-2xl uppercase flex items-center -ml-3">
                            <MdChevronRight size={32} className="text-gold" />
                            {section.title[language] ?? section.title.it}
                        </span>
                        {section.items.map((item, itemIdx) => (
                            <div key={itemIdx} className="w-full flex mt-1">
                                <b>{item[language] ?? item.it}</b>
                            </div>
                        ))}
                    </div>
                ))}
                <Separator />
                <span className="text-2xl uppercase flex items-center -ml-3">
                    <MdChevronRight size={32} className="text-gold" />
                    {t("page-ristorante:alwaysIncluded")}
                </span>
                {halfAndFullBoardMenu.alwaysIncluded.map(
                    (includedItem, itemIdx) => (
                        <div key={itemIdx} className="w-full flex mt-1">
                            <b>{includedItem[language] ?? includedItem.it}</b>
                        </div>
                    )
                )}
                <Separator />
                <span className="text-2xl uppercase flex items-center -ml-3">
                    <MdChevronRight size={32} className="text-gold" />
                    {t("page-ristorante:wines")}
                </span>
                <small class="mt-1 mb-3">{t("page-ristorante:winesSlogan")}</small>
                {halfAndFullBoardMenu.wines.map((item, itemIdx) => (
                    <div key={itemIdx} className="w-full flex mt-1">
                        <div className="grow flex flex-col">
                            <b>
                                {item.title[language] ?? item.title.it}
                            </b>
                            {item.description !== null ? (
                                <small>
                                    <i>
                                        {item.description[language] ??
                                            item.description.it}
                                    </i>
                                </small>
                            ) : null}
                        </div>
                        <div className="pl-4">{item.amount}€</div>
                    </div>
                ))}
            </Container>
        </Layout>
    );
}
