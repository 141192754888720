import restaurantMenu from "./restaurantMenu";

// TODO: translate it in english after a preliminary review of the content
const halfAndFullBoardMenu = {
    sections: [
        {
            items: [
                {
                    it: "Pappardelle al ragù bianco di vitello della Valdichiana",
                },
                {
                    it: "Ravioli di ricotta e spinaci al pomodoro e olio al basilico",
                },
                {
                    it: "Strangozzi alla norcina con salsiccia, tartufo, pecorino e panna",
                },
                {
                    it: "Mezze maniche all’amatriciana",
                },
                {
                    it: "Gnocchi verdi fatti a mano alla Francescana",
                },
                {
                    it: "Zuppa del giorno",
                },
            ],
            title: {
                it: "Primi piatti",
            },
        },
        {
            items: [
                {
                    it: "Maialino arrosto al finocchio selvatico",
                },
                {
                    it: "Arrosto di vitello con salsa di cottura e funghi misti",
                },
                {
                    it: "Spezzatino di cinghiale alla cacciatora",
                },
                {
                    it: "Grigliata mista con agnello, salsiccia, petto di pollo e pancetta di maiale",
                },
                {
                    it: "Prosciutto crudo di Norcia e caciotta locale",
                },
            ],
            title: {
                it: "Secondi piatti",
            },
        },
    ],
    alwaysIncluded: [
        {
            it: "Contorno del giorno",
        },
        {
            it: "Vino della casa e acqua minerale",
        },
    ],
    // It works under the assumption that wines are the last section
    wines: restaurantMenu.sections.find(section => section.title.it === "Selezione di vini Umbri").items,
};

export default halfAndFullBoardMenu;
